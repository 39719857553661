import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { v4 } from 'uuid';

import styles from './publiftad.module.css';
import Icon from '../Icon';
import { useMediaQuery } from 'react-responsive';

function PubliftAd({
    bigHeaderSize,
    fuseId,
    className = '',
    onAdCompleted = () => {},
}) {
    // Responsive
    const isMobile = useMediaQuery({ maxWidth: 630 });

    // Auth state
    const isLoggedIn = useSelector(state => state.auth.isLoggedIn);
    const isPro = useSelector(state => state.me.is_pro);
    const allow_web_ads = useSelector(state => state.auth.healthCheck.allow_web_ads);
    
    // Create a unique ID for this ad instance
    const randomId = useMemo(() => v4(), []);
    const uniqueId = `publift-ad-${randomId}`;

    // State
    const [calculatedWidth, setCalculatedWidth] = useState(null)
    const [adLoaded, setAdLoaded] = useState(false)
    
    // Refs
    const adRegistered = useRef(false);
    const adLoadedRef = useRef(false);
    
    //Keep track of ad loaded state
    useEffect(() => {
        adLoadedRef.current = adLoaded
    }, [adLoaded])

    //Keep calculated width updated by checking the width of the ad component wrapper, and listening for changes of window resize
    useEffect(() => {
        const handleResize = () => {
            const adComponentWrapper = document.getElementById(uniqueId)
            if (adComponentWrapper) {
                setCalculatedWidth(adComponentWrapper.clientWidth)
            }
        }
        
        handleResize()

        window.addEventListener('resize', handleResize)

        return () => window.removeEventListener('resize', handleResize)
    }, [uniqueId])

    // Register the ad with Publift when component mounts
    useEffect(() => {
        if (!allow_web_ads || (isLoggedIn && isPro)) {
            return;
        }
        
        // Get reference to fusetag
        const fusetag = window.fusetag || (window.fusetag = { que: [] });
        
        // Register the ad after it's been added to the DOM
        fusetag.que.push(() => {
            if (!adRegistered.current) {
                fusetag.registerZone(uniqueId);
                adRegistered.current = true;
            }
        });
        
        const checkAdLoaded = () => {
            if (adLoadedRef.current) {
                return
            }

            const adComponentWrapper = document.getElementById(uniqueId)
            if (adComponentWrapper) {
                // Check for any iframe element at any depth inside the ad wrapper
                // querySelector will find the first matching element at any depth in the DOM tree
                const iframe = adComponentWrapper.querySelector('iframe')
                if (iframe) {
                    setAdLoaded(true)
                    onAdCompleted()
                }
            }
        }

        // Initial check
        checkAdLoaded()

        // Set up mutation observer to watch for changes
        const observer = new MutationObserver(checkAdLoaded)
        
        const adComponentWrapper = document.getElementById(uniqueId)
        if (adComponentWrapper) {
            observer.observe(adComponentWrapper, {
                childList: true,
                subtree: true
            })
        }

        return () => observer.disconnect()
    }, [uniqueId, fuseId, isLoggedIn, isPro, allow_web_ads]);
    
    // Don't render the ad if user is logged in and is a pro user
    if (!allow_web_ads || (isLoggedIn && isPro)) {
        return null;
    }
    
    return (
        <div 
        className={`${className} ${isMobile ? "" : "margin-left-16 margin-right-16"}`}
        style={adLoaded ? {} : { marginTop: 0 }}
        >
            <div 
            id={uniqueId}
            data-fuse={fuseId}
            className="flex-center"
            style={{ maxWidth: calculatedWidth ? calculatedWidth : undefined, overflow: 'hidden', minHeight: fuseId.includes("header") ? isMobile ? 90 : bigHeaderSize ? 250 : 100 : undefined }}
            >
                {/*
                <div style={{ width: '100%', height: 100, backgroundColor: 'red' }} className='flex-center'>
                    <h4>{fuseId}</h4>
                </div>
                */}
            </div>

            {adLoaded && (
                <div className="flex-center margin-top-12">
                    <Link to="/pro" className={`flex-center ${styles.removeAds}`}>
                        <Icon icon='close' size={16} style={{ marginRight: 2, transform: 'scale(0.95) translateY(0.2px)' }} />
                        
                        <h6 style={{ fontSize: 12 }}>REMOVE ADS</h6>
                    </Link>
                </div>
            )}
        </div>
    );
}

export default React.memo(PubliftAd);